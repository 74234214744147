<template>
  <v-container
    id="teacherlist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Преподаватели
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Преподаватели</span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0 pl-0"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  rounded
                  @click="createTeacher"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </v-col>
              <v-col
                class="pt-0 pb-0"
              />

              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="onlyArchived"
                  class="mt-0 mb-0"
                  :label="onlyArchivedLabel"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :sort-by="['order']"
            :sort-desc="[false]"
            class="teachers"
            @click:row="singleTeacher"
          >
            <template v-slot:item.photo="{ item }">
              <v-img
                v-if="item.photo"
                :src="imageLink(item.id)"
                :lazy-src="imageLink(item.id)"
                aspect-ratio="1.5"
                width="100px"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </template>
            <template v-slot:item.status="{ item }">
              <v-icon v-if="item.status">
                mdi-eye
              </v-icon>
              <v-icon v-else>
                mdi-eye-off
              </v-icon>
            </template>
            <template v-slot:item.alfa_id="{ item }">
              <div class="text-center">
                <v-btn-toggle
                  v-model="toggle_multiple"
                  class="mx-1"
                  rounded
                  multiple
                >
                  <v-btn
                    color="primary"
                    small
                    title="Редактировать"
                    @click.stop="singleTeacher(item)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    color="warning"
                    small
                    title="Учебная сетка занятий преподавателя"
                    @click.stop="lessonsTeacher(item)"
                  >
                    <v-icon>
                      mdi-timetable
                    </v-icon>
                  </v-btn>
                  <v-btn
                    color="success"
                    small
                    title="Отчет о проведенных занятиях"
                    @click.stop="statTeacher(item)"
                  >
                    <v-icon>
                      mdi-chart-areaspline
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
                <v-btn-toggle
                  v-model="toggle_multiple"
                  class="mx-1"
                  rounded
                  multiple
                >
                  <v-btn
                    color="secondary"
                    small
                    title="Поместить выше"
                    @click.stop="orderUp(item.id)"
                  >
                    <v-icon>
                      mdi-arrow-up
                    </v-icon>
                  </v-btn>
                  <v-btn
                    color="secondary"
                    small
                    title="Поместить ниже"
                    @click.stop="orderDown(item.id)"
                  >
                    <v-icon>
                      mdi-arrow-down
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </div>
            </template>
            <!--
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ archived: !item.status }"
                  @click="singleTeacher(item)"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td><span v-if="item.course">{{ item.course.name }}</span></td>
                  <td><span v-html="item.teacherSummary" /></td>
                  <td>{{ item.comment }}</td>
                  <td>

                  </td>
                </tr>
              </tbody>
            </template>
            -->
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .v-data-table tr, .v-data-table td {
    cursor: pointer !important;
  }

  .help-pointer {
    cursor: pointer;
  }

  .v-data-table__wrapper table tr td {
    cursor: pointer;
  }

  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }

  .help-pointer {
    cursor: pointer;
  }
  .group-chip {
    height: auto !important;
    margin-bottom: 1px !important;
    display: table-cell;
    white-space: nowrap;
  }
</style>

<script>
  import teachersApi from '../services/TeachersApi'
  import CRMStorage from '../services/CRMStorage'

  export default {
    data () {
      return {
        search: CRMStorage.getItem('TCHRL_search', ''),
        page: 1,
        itemsPerPage: CRMStorage.getItem('TCHRL_itemsPerPage', 10),
        onlyArchived: CRMStorage.getItem('TCHRL_onlyArchived', false),
        headers: [
          {
            sortable: false,
            text: '',
            value: 'status',
            filter: value => {
              if (this.onlyArchived) {
                return value === 0
              }
              return value === 1
            },
          },
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Фото',
            value: 'photo',
          },
          {
            sortable: true,
            text: 'ФИО',
            value: 'name',
          },
          {
            sortable: true,
            text: 'Телефон',
            value: 'phone',
          },
          {
            sortable: true,
            text: 'Email',
            value: 'email',
          },
          {
            sortable: true,
            text: 'Комментарий',
            value: 'comment',
          },
          {
            sortable: true,
            text: 'П',
            value: 'order',
          },
          {
            sortable: false,
            text: 'Действия',
            value: 'alfa_id',
            width: 210,
          },

        ],
        toggle_multiple: undefined,
        items: [],
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
      }
    },

    computed: {
      onlyArchivedLabel () {
        if (this.items.length < 1) {
          return 'Показать архивные'
        }
        return 'Показать архивные (' + this.items.filter((item) => item.status === 0).length + ')'
      },
    },
    watch: {
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('TCHRL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('TCHRL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('TCHRL_page', val)
      },
      onlyArchived: function (val) {
        this.page = 1
        CRMStorage.setItem('TCHRL_onlyArchived', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('TCHRL_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('TCHRL_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Преподаватели | ' + process.env.VUE_APP_NAME
      if (!this.itemsPerPage) this.itemsPerPage = 10
      this.fetchData()
    },
    methods: {
      singleTeacher (item) {
        CRMStorage.setItem('TCHRL_scrollY', window.scrollY)
        this.$router.push('/teacher/' + item.id)
      },
      statTeacher (item) {
        CRMStorage.setItem('TCHRL_scrollY', window.scrollY)
        this.$router.push('/teacher/stat/' + item.id)
      },
      lessonsTeacher (item) {
        CRMStorage.setItem('TCHRL_scrollY', window.scrollY)
        this.$router.push('/teacher/lessons/' + item.id)
      },
      createTeacher () {
        CRMStorage.setItem('TCHRL_scrollY', window.scrollY)
        this.$router.push('/teacher/create')
      },
      handleError (errorResponse) {
        let data = errorResponse.response.data
        if (errorResponse.response.status === 422) {
          this.fieldsErrors = data
        } else if (errorResponse.response.status === 401) {
          this.error = 'Неверный логин или пароль! Войдите в систему заново!'
        } else if (data.message) {
          this.error = data.message
        } else {
          this.error = JSON.stringify(data, null, 2)
        }
        this.loading = false
      },
      orderUp (id) {
        this.loading = true
        teachersApi
          .orderUp(id)
          .then(response => {
            this.fetchData()
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      orderDown (id) {
        this.loading = true
        teachersApi
          .orderDown(id)
          .then(response => {
            this.fetchData()
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      fetchData () {
        this.loading = true
        teachersApi
          .fetchTeachers()
          .then(response => {
            this.items = response
            this.loading = false
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      dayTime (dayIsActive, time) {
        if (dayIsActive && time) {
          return time.substring(0, 5)
        }
        return '-'
      },
      imageLink (id) {
        return process.env.VUE_APP_OLD_URL + 't/100/100/' + id
      },
    },
  }
</script>
